import React from 'react';
import { Typography } from '@mui/material';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';
import GTranslateOutlinedIcon from '@mui/icons-material/GTranslateOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import CoPresentOutlinedIcon from '@mui/icons-material/CoPresentOutlined';

import { ModalContent } from '../styles/ScheduleGridStyles';
import { useTranslation } from 'react-i18next';


export default function ActivityModal({ open, onClose, selectedActivity }) {
  const { t } = useTranslation();

  if (!selectedActivity) return null;
  return (
    <div>
      {open && (
        <ModalContent>
          <Typography variant="body1">
            <strong>{ t('pages.activityList.globalSchedule.time')}: </strong> {selectedActivity.startTime} - {selectedActivity.endTime}
          </Typography>
          <br />
          <Typography variant="h6">{selectedActivity.title}</Typography>
          <br />
          <Typography variant="body2">{selectedActivity.description}</Typography>
          <br />
          <Typography variant="subtitle1">
            <CoPresentOutlinedIcon style={{ fontSize: '18px' }} /> <strong>{ t('pages.activityList.globalSchedule.modal.speaker')}:</strong>{' '}
            {selectedActivity.speaker}
          </Typography>
          <Typography variant="body1">
            <LocationOnOutlinedIcon style={{ fontSize: '18px' }} /> <strong>{t('pages.activityList.globalSchedule.modal.space')}:</strong>{' '}
            {selectedActivity?.space}
          </Typography>
          <Typography variant="body1">
            <QueryBuilderOutlinedIcon style={{ fontSize: '18px' }} /> <strong>{t('pages.activityList.globalSchedule.modal.duration')}:</strong>{' '}
            {selectedActivity?.workload}
          </Typography>
          <Typography variant="body1">
            <GTranslateOutlinedIcon style={{ fontSize: '18px' }} /> <strong>{t('pages.activityList.globalSchedule.modal.language')}:</strong>{' '}
            {selectedActivity?.language}
          </Typography>
          <Typography variant="body1">
            <MapOutlinedIcon style={{ fontSize: '18px' }} /> <strong>{t('pages.activityList.globalSchedule.modal.track')}:</strong>{' '}
            {selectedActivity?.trackName}
          </Typography>
        </ModalContent>
      )}
    </div>
  );
}
