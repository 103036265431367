import React from 'react';
import {
    //Box,
    Tab,
    Tabs,
  } from '@mui/material';
import { converterDataParaIdioma } from '../utils';
import { useTranslation } from 'react-i18next';

export default function CustomTabs({
    days,
    currentTab,
    handleTabChange
}) {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  
  return (

  <Tabs
    value={currentTab}
    onChange={handleTabChange}
    TabIndicatorProps={{
      style: {
        display: "none", // Esconde o indicador padrão
      },
    }}
    sx={(theme) => ({
      "& MuiTabs-flexContainer": {

      },
      "& .MuiTab-root": {
        borderRadius: "8px 8px 0 0",
        padding: "8px 16px",
        backgroundColor: theme.palette.background.default,
        transition: "background-color 0.3s",
        "&:hover": {
          backgroundColor: theme.palette.background.default,
        },
        "&.Mui-selected": {
          backgroundColor: theme.palette.background.paper,
          border: "1px solid " + (theme.palette.mode === 'light' ? '#ddd' : '#111'),
          borderBottomWidth: "0px",
          fontWeight: "bold",
        },
      },
      "& .MuiTabs-scrollButtons": {
        width: "36px",
        height: "36px",
        borderRadius: "50%",
        //backgroundColor: theme.palette.background.paper,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: '-36px',
        opacity: 0,
        zIndex: 2,
        "&:hover": {
          backgroundColor: theme.palette.action.hover,
        },
        "&.Mui-disabled": {
          backgroundColor: theme.palette.action.disabledBackground,
        },
      },
    })}
    variant="scrollable" // Habilita comportamento rolável
    scrollButtons // Adiciona botões para rolar
    allowScrollButtonsMobile // Mostra os botões em mobile
  >
    {days.map((day) => (
      <Tab key={day} label={converterDataParaIdioma(day, currentLanguage)} />
    ))}
  </Tabs>

  );
}
