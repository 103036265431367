import React from "react";
import { Box, Skeleton } from "@mui/material";

const LoadingSkeleton = ({ spaces }) => {
  return (
    <Box
      width="100%"
      padding={2}
      overflow="auto"
      bgcolor="rgba(0, 0, 0, 0.05)"
      borderRadius={2}
    >
      <Box
        display="grid"
        gridTemplateColumns={`80px repeat(${spaces}, 1fr)`} // Coluna fixa + dinâmicas
        gap={1}
        width="100%"
      >
        {/* Cabeçalho */}
        <Box bgcolor="rgba(0, 0, 0, 0.1)" height="40px" borderRadius={1} />
        {Array.from({ length: spaces }, (_, index) => (
          <Box
            key={`header-${index}`}
            bgcolor="rgba(0, 0, 0, 0.1)"
            height="40px"
            borderRadius={1}
          />
        ))}

        {/* Corpo */}
        {Array.from({ length: 5 }, (_, rowIndex) => (
          <>
            <Box
              key={`time-${rowIndex}`}
              bgcolor="rgba(0, 0, 0, 0.1)"
              height="80px"
              borderRadius={1}
            />
            {Array.from({ length: spaces }, (_, colIndex) => (
                <Skeleton
                    key={`cell-${rowIndex}-${colIndex}`}
                    variant="rectangular"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    bgcolor="rgba(0, 0, 0, 0.1)"
                    height="80px"         // altura fixa
                    minHeight="80px"      // altura mínima fixa
                    width="100%"          // garantir a largura total da célula
                    borderRadius={1}
                    overflow="hidden"
                />
            ))}
          </>
        ))}
      </Box>
    </Box>
  );
};

export default LoadingSkeleton;
