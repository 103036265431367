import { useMemo } from 'react';

export default function useClosestSlot(slotsByHour) {
  const currentTime = useMemo(() => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  }, []);

  const closestSlotIndex = useMemo(() => {
    let closestIndex = -1;
    let minDifference = Infinity;
  
    slotsByHour.flat().forEach((slot, index) => {
      const [slotHours, slotMinutes] = slot.split(':').map(Number);
      const [currentHours, currentMinutes] = currentTime.split(':').map(Number);
  
      const slotTimeInMinutes = slotHours * 60 + slotMinutes;
      const currentTimeInMinutes = currentHours * 60 + currentMinutes;
  
      const difference = Math.abs(currentTimeInMinutes - slotTimeInMinutes);
  
      if (difference < minDifference) {
        minDifference = difference;
        closestIndex = index;
      }
    });
  
    return closestIndex;
  }, [slotsByHour, currentTime]);

  return { closestSlotIndex, currentTime };
}
