import React from 'react';
import { Stack, Chip } from '@mui/material';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import LabelOffOutlinedIcon from '@mui/icons-material/LabelOffOutlined';

export default function FilterTracks({ tracks, selectedTracks, onToggleTrack }) {
  return (
    <Stack
      direction="row"
      spacing={1}
      sx={(theme) => ({ 
        display: 'flex',
        justifyContent: "center",
        flexWrap: 'wrap', 
        gap: '10px', background: theme.palette.background.paper,
        border: "1.5px solid " + (theme.palette.mode === 'light' ? '#ddd' : '#111'),
        borderTop: "0px",
        padding: "16px",
        paddingTop: "24px", 
        borderBottomLeftRadius: "16px",
        borderBottomRightRadius: "16px"
       })}
    >
      {tracks.map((t, index) => (
        <Chip
          key={t.id}
          label={
            <div style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                color: "#FFF",
                gap: "4px"
              }}>
              { (selectedTracks?.length === 0 || !selectedTracks?.length) ? 
                <LabelOutlinedIcon style={{fill: "#FFF", fontSize: "18px"}}/> : 
                (selectedTracks.includes(t.id) ? 
                  <LabelOutlinedIcon style={{fill: "#FFF", fontSize: "18px"}}/>  : 
                  <LabelOffOutlinedIcon style={{fill: "#FFF", fontSize: "18px"}}/>)
              } <span style={{ display: "inline-block", overflow: "hidden", textOverflow: "ellipsis" }}>
                  {t.label}
                </span>
            </div>
          }
          sx={{
            backgroundColor: t.color,
            '@media (hover: none)': {
              '&:hover': {
                backgroundColor: t.color
              },
            },
            opacity: (selectedTracks?.length === 0 || !selectedTracks?.length) ? 1 : (selectedTracks.includes(t.id) ? 1 : 0.5),
            marginLeft: index === 0 ? "80px" : "0px"
          }}
          onClick={() => onToggleTrack(t.id)}
          clickable
        />
      ))}
    </Stack>
  );
}
