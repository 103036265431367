/**
 * Converte data/hora "YYYY-MM-DDTHH:mm:ss..." em "HH:MM" (ex.: "10:00").
 */
function dateTimeToHourMinute(dateTimeStr) {
  const dateObj = new Date(dateTimeStr);
  const hh = String(dateObj.getHours()).padStart(2, '0');
  const mm = String(dateObj.getMinutes()).padStart(2, '0');
  return `${hh}:${mm}`;
}
  
/**
 * Extrai a data no formato "DD-MM-YYYY" de "YYYY-MM-DDTHH:mm:ss...".
 * Exemplo: "2024-11-27T03:00:00.000+00:00" => "27-11-2024"
 */
function extractDayKey(dateTimeStr) {
  const d = new Date(dateTimeStr);
  const day = String(d.getDate()).padStart(2, '0');
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const year = String(d.getFullYear());
  return `${day}-${month}-${year}`;
}
  
/**
 * Junta todos os texts dos blocks do campo 'description' (Draft.js-like).
 */
function extractDescriptionText(description) {
  if (!description || !description.blocks) return '';
  return description.blocks.map((block) => block.text).join('\n');
}

/**
 * Percorre o array de activities (extenso) e retorna:
 *  {
 *    groupedActivities: {
 *      '27-11-2024': [ ...atividades ],
 *      '28-11-2024': [ ... ],
 *      ...
 *    },
 *    tracks: [
 *      { id, label, color },
 *      ...
 *    ]
 *  }
 */
export function adaptActivities(activities) {
  // Para gerar tracks únicas, usamos um Map (key = trackId, value = {id,label,color})
  const trackMap = new Map();

  // Objeto final com chaves = 'DD-MM-YYYY', valor = []
  const groupedActivities = {};

  const sortedActivities = [...activities].sort((a, b) => {
    const aStartTime = a.schedule?.[0]?.startDateTime || '9999-12-31T23:59';
    const bStartTime = b.schedule?.[0]?.startDateTime || '9999-12-31T23:59';
    return new Date(aStartTime).getTime() - new Date(bStartTime).getTime();
  });

  for (const item of sortedActivities) {
    // Dados básicos de cada activity
    const activityId = item.id;
    const activityTitle = item.name || 'Sem título';
    const placeName = item.place?.name ?? '(Sem lugar)';
    const descriptionText = extractDescriptionText(item.description);
    const language = item?.language;
    const price = item?.price;
    const workload = item?.workload;
    const trackNameLabel = item?.track?.name;
    

    // Se houver speakers, pegamos o primeiro ou concatenamos, etc.
    const speakerObj = item.speakers?.[0]?.speaker;
    const speakerName = speakerObj ? speakerObj.name : 'Sem palestrante';

    // Track => track.id, track.name, track.calendarColor (pode ser nulo/undefined)
    const trackId = item.track?.id ?? 'no-track';
    const trackName = item.track?.name ?? 'Sem Track';
    const trackColor = item.track?.calendarColor ?? '#ccc';

    // Armazenamos no trackMap (para não duplicar)
    trackMap.set(trackId, {
      id: String(trackId),
      label: trackName,
      color: trackColor,
    });

    // Para cada schedule (intervalo), criamos uma "sub-atividade" adaptada
    const schedules = item.schedule ?? [];
    if (schedules.length > 0) {
      for (const sched of schedules) {
        const startTime = dateTimeToHourMinute(sched.startDateTime);
        const endTime = dateTimeToHourMinute(sched.endDateTime);
        const dayKey = extractDayKey(sched.startDateTime); // Ex: "27-11-2024"

        // Monta a atividade final
        const adapted = {
          id: activityId,
          startTime,
          endTime,
          space: placeName,
          title: activityTitle,
          speaker: speakerName,
          track: String(trackId),  // Ou use trackName, depende do seu componente
          description: descriptionText,
          language: language,
          workload: workload,
          price: price,
          trackName: trackNameLabel
        };

        // Insere no groupedActivities[dayKey]
        if (!groupedActivities[dayKey]) {
          groupedActivities[dayKey] = [];
        }
        groupedActivities[dayKey].push(adapted);
      }
    } else {
      // Se não houver schedule, crie uma atividade "padrão" ou ignore
      const dayKey = 'sem-data';
      const adapted = {
        id: activityId,
        startTime: '09:00',
        endTime: '10:00',
        space: placeName,
        title: activityTitle,
        speaker: speakerName,
        track: String(trackId),
        description: descriptionText,
      };
      if (!groupedActivities[dayKey]) {
        groupedActivities[dayKey] = [];
      }
      groupedActivities[dayKey].push(adapted);
    }
  }

  // Gera array de tracks a partir do Map
  const tracks = Array.from(trackMap.values());

  return {
    groupedActivities,
    tracks,
  };
}