import React from 'react';
import { Typography, TableHead, TableRow, Stack } from '@mui/material';
import { StickyTableCell, StyledTableCell } from '../styles/ScheduleGridStyles';

export default function TableHeadCustom({ spaces, labelHour = "Horário" }) {
  return (
    <TableHead>
        <TableRow>
            <StickyTableCell 
                sx={(theme) => ({ 
                    fontWeight: 'bold', width: 80,
                    borderBottom: '1px solid ' + (theme.palette.mode === 'light' ? "#ddd" : "#111")
                })}>
                    {labelHour}
                </StickyTableCell>
            {spaces.map((space) => (
                <StyledTableCell 
                    sx={(theme) => ({ 
                        background: theme.palette.background.paper + " !important", 
                        fontWeight: 'bold', 
                        borderLeft: '1px solid ' + (theme.palette.mode === 'light' ? "#ddd" : "#111"),
                        borderBottom: '1px solid ' + (theme.palette.mode === 'light' ? "#ddd" : "#111") + " !important"
                    })}
                    key={space.title}
                >
                    <Stack direction="column" alignItems="center" spacing={1}  >
                        {space.avatar && (
                            <img
                                src={space.avatar}
                                alt={space.title}
                                style={{ width: 24, height: 24, borderRadius: '50%', objectFit: "cover"}}
                            />
                        )}
                        <Typography variant="body2" sx={{textAlign: "center" }}>{space.title}</Typography>
                    </Stack>
                </StyledTableCell>
            ))}
        </TableRow>
    </TableHead>
  );
}
