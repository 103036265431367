import { Box, Grid, Skeleton, Typography } from '@mui/material';
import BoxW from '../../../components/wrapper/BoxW';
import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect, useState } from 'react';
import useLocation from '../../../components/hook/useLocation';
import ButtonW from '../../../components/wrapper/ButtonW';
import { ManipulateStep } from '../registration';
import LoadingSkeleton from '../../activities/global-schedule/LoadingSchedule';
import { useEditionChange } from '../../../components/context/EditionChangeContext';
import ActivityService from '../../../services/ActivityService';
import { adaptActivities } from '../../activities/global-schedule/adapter';
import EditionService from '../../../services/EditionService';
import ScheduleGrid from '../../../components/main-scheduler';

const Step1 = ({ item }) => {
  const { t } = useTranslation();
  const { currentEdition } = useEditionChange();

  // const { currentUser } = useUserChange();
  const { activeStep, setActiveStep } = useContext(ManipulateStep);
  // const { handleValidRange } = useTrack();
  const { formatLocaleDateStringWithFormat, formatCurrency } = useLocation();
  // const [events, setEvents] = React.useState([]);
  const formatDate = { day: '2-digit', month: '2-digit', year: '2-digit' };

  const [activities, setActivities] = useState([]);
  const [isLoadingActivities, setIsLoadingActivities] = useState(false);

  const [tracks, setTracks] = useState([]);
  const [resources, setResources] = useState([]);

  const onSubmit = () => setActiveStep(activeStep + 1);

  useEffect(() => {
    if (!currentEdition) return;
    setIsLoadingActivities(true);
    ActivityService.findAllByEditionAndConfirmed(currentEdition.id, true).then((response) => {
      if (response.status === 200) {
        //setActivities(response.data);
        //console.log("response.data ", response.data)
        const {
          groupedActivities,
          tracks,
        } = adaptActivities(response.data)
        setActivities(groupedActivities)
        setTracks(tracks)
      }
      setIsLoadingActivities(false);
    });
    EditionService.findAllActivitiesByEdition(currentEdition.id, true).then((response) => {
      if (response.data?.[0]?.track?.edition?.institution) {
        const institution = response.data[0]?.track?.edition?.institution;
        //const initialDate = response.data[0]?.track?.initialDate;
        //const finalDate = response.data[0]?.track?.finalDate;

        if (institution && Array.isArray(institution.spaces)) {
          // Ordenando o array pelo campo 'number'
          const sortedSpaces = institution.spaces.sort((a, b) => a.number - b.number);

          const formattedResources = sortedSpaces.map((space) => ({
            admin_id: space.id,
            title: space.name,
            mobile: space.number,
            avatar: space.spaceFlag ? `https://flagcdn.com/w320/${space.spaceFlag}.png` : null,
          }));

          setResources(formattedResources);
        } else {
          console.warn('Nenhuma instituição ou espaços retornados.');
        }
        //setActivities(response.data);
      }
      setIsLoadingActivities(false);
    });
  }, [currentEdition, t]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" fontWeight="bold">
          {t('pages.registration.step1.title', { eventName: item.title })}
        </Typography>
      </Grid>
      {/* Column 1 */}
      <Grid item xs={12} sm={6} md={4} xl={3} mt={{ xs: 2, sm: 6 }} pr={2}>
        <Typography variant="h6" fontWeight="bold" mb={1}>
          {t('pages.registration.step1.column1.title')}
        </Typography>
        <Box
          component="img"
          src={item.image}
          alt="2024"
          sx={(theme) => ({
            width: { xs: '215px', sm: '115px' },
            height: { xs: '215px', sm: '115px' },
            borderRadius: '16px',
            backgroundColor: theme.palette.sidebar.backgroundColor,
            p: 1,
          })}
        />
        {item.initialDate ? (
          <>
            <Typography fontWeight="bold" mt={2}>
              {`${formatLocaleDateStringWithFormat(item.initialDate, formatDate)} > ${formatLocaleDateStringWithFormat(
                item.finalDate,
                formatDate
              )}`}
            </Typography>
            <Typography variant="body2">
              {t('pages.registration.step1.column1.text1')}
              {formatLocaleDateStringWithFormat(item.endRegistration, formatDate)}
            </Typography>
            <br />
            {item.price > 0 ?? (
              <>
                <Typography variant="h6" fontWeight="bold">
                  {formatCurrency(item.price)}
                  <br />
                </Typography>
                <Typography variant="body2">
                  {`${t('pages.registration.step1.column1.text2')} 3 x `}
                  {formatCurrency(item.price / 3)}
                </Typography>
              </>
            )}
            <Typography textAlign={'justify'}>{item.description}</Typography>
          </>
        ) : (
          <>
            <Skeleton variant="text" width={'50%'} />
            <Skeleton variant="text" width={'50%'} sx={{ fontSize: '1rem' }} />
            <br />
            <Skeleton variant="text" width={'50%'} sx={{ fontSize: '2rem' }} />
          </>
        )}
      </Grid>
      {/* Column 2 */}
      <Grid item xs={12} sm={6} md={9} mt={6}>
        <Typography variant="h6" fontWeight="bold" mb={2}>
          {t('pages.registration.step1.column3.title')}
        </Typography>
        <BoxW width="100%">
          {isLoadingActivities ? (
            <LoadingSkeleton spaces={4} />
          ) : (
            tracks?.length > 0 && (
              <ScheduleGrid
                spaces={resources}
                tracks={tracks}
                activities={activities}
              />
            )
          )}
        </BoxW>
      </Grid>
      <Box
        width="100%"
        mt={2}
        p={{ xs: 0, sm: 2 }}
        flexDirection="row"
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="end"
      >
        <Box p={1} width={{ xs: '100%', sm: '20%' }}>
          <ButtonW fullWidth secondary onClick={onSubmit} size="large" sx={{ fontSize: 24, fontWeight: 'bold', py: 2 }}>
            {t('pages.editSatisfactionSurvey.next')}
          </ButtonW>
        </Box>
      </Box>
    </Grid>
  );
};

export default Step1;
