import React from 'react';
import {
  Box,
  Modal,
  Paper,
  Table,
  TableBody,
  TableRow,
} from '@mui/material';

import { 
  StyledTableContainer, 
  StyledTableCell, 
  StickyTableCell, 
  MainContainer,
  WrapperHeaderOptionsTable,
  DinamicContainerFilters,
  WrapperFilters
} from './styles/ScheduleGridStyles';

import {
  generateSlotsByHour,
  buildTableData,
} from './utils';


import useClosestSlot from './hooks/useClosestSlot';
import ActivityCard from './parts/ActivityCard';
import ActivityModal from './parts/ActivityModal';
import TableHeadCustom from './parts/TableHeadCustom';
import useActivitiesFilter from './hooks/useActivitiesFilter';
import FilterTracks from './parts/FilterTracks';
import CustomTabs from './parts/CustomTabs';
import ArrowButtonsNavigator from './parts/ArrowButtonsNavigator';
import MultiSelectWithSearch from './parts/MultiSelectWithSearch';
import ButtonVisibleFilters from './parts/ButtonVisibleFilters';
import { useTranslation } from 'react-i18next';

export default function ScheduleGrid({
  activities = {
    '27-11-2024': [
      {
        id: 1,
        startTime: '08:00',
        endTime: '08:40',
        space: 'Brasil',
        title: 'Exemplo 1',
        speaker: 'Fulano',
        track: '0',
        description: 'Exemplo de descrição.',
      },
    ],
  },
  spaces = [
    { title: 'Brasil' },
    { title: 'Argentina' },
    { title: 'Chile' },
  ],
  tracks = [
    { id: '0', label: 'Técnica', color: '#FFB74D' },
    { id: '1', label: 'Negócios', color: '#81C784' },
  ],
  startHour = 8,
  endHour = 18,
  step = 5,
}) {

    const { t } = useTranslation();

  // Slots de tempo
  const slotsByHour = React.useMemo(
    () => generateSlotsByHour(startHour, endHour, step),
    [startHour, endHour, step]
  );

  // Dias disponíveis
  const days = React.useMemo(() => Object.keys(activities), [activities]);
  
  // Tab atual
  const [currentTab, setCurrentTab] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  // Hook de filtro
  const {
    selectedTracks,
    speakerOptions,
    activitiesOptions,
    setSelectedSpeakers,
    setSelectedActivities,
    handleTrackToggle,

    filtered
  } = useActivitiesFilter(activities, activities?.[days?.[currentTab]] || []);

  // Monta dados para a tabela
  const tableData = React.useMemo(() => {
    return buildTableData({
      dayActivities: filtered,
      slotsByHour,
      spaces,
    });
  }, [filtered, slotsByHour, spaces]);

  // Lógica do slot atual
  const { closestSlotIndex } = useClosestSlot(slotsByHour);

  // Controle do Modal
  const [modalOpen, setModalOpen] = React.useState(false);
  const [selectedActivity, setSelectedActivity] = React.useState(null);
  const handleCardClick = (activity) => {
    setSelectedActivity(activity);
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedActivity(null);
  };

  const [visibleFilters, setVisibleFilters] = React.useState(false);

  // Lógica do scroll suave
  const containerRef = React.useRef(null);
  const columnWidth = 290; // Largura de uma coluna
  const [visibleColumnIndex, setVisibleColumnIndex] = React.useState(0);

  const now = new Date();

  const handleNextColumn = () => {
    if (visibleColumnIndex < spaces.length - 1) {
      setVisibleColumnIndex((prev) => prev + 1);
      if (containerRef.current) {
        containerRef.current.scrollTo({
          left: containerRef.current.scrollLeft + columnWidth,
          behavior: 'smooth',
        });
      }
    }
  };

  const handlePrevColumn = () => {
    if (visibleColumnIndex > 0) {
      setVisibleColumnIndex((prev) => prev - 1);
      if (containerRef.current) {
        containerRef.current.scrollTo({
          left: containerRef.current.scrollLeft - columnWidth,
          behavior: 'smooth',
        });
      }
    }
  };

  return (
    <MainContainer>
      <WrapperHeaderOptionsTable>
        <CustomTabs
          days={days}
          currentTab={currentTab}
          handleTabChange={handleTabChange}
        />

        <Box sx={{display: "flex", flexDirection: "row", gap: "24px", height: "100%", flexWrap: "wrap"}}>
          <ButtonVisibleFilters
            visibleFilters={visibleFilters}
            setVisibleFilters={setVisibleFilters}
            labelShowFilters={ t('pages.activityList.globalSchedule.filters.showFilters')}
            labelHiddenFilters={ t('pages.activityList.globalSchedule.filters.hiddenFilters')}
          />

          <Box>
            <ArrowButtonsNavigator
              handlePrevColumn={handlePrevColumn}
              visibleColumnIndex={visibleColumnIndex}
              handleNextColumn={handleNextColumn}
              spaces={spaces}
            />
          </Box>
        </Box>
      </WrapperHeaderOptionsTable>
      
      <DinamicContainerFilters visibleFilters={visibleFilters}>
        <WrapperFilters>
          <MultiSelectWithSearch
            placeholder={ t('pages.activityList.globalSchedule.filters.labelActivities')}
            items={activitiesOptions}
            onSelectionChange={(e) => {
              setSelectedActivities(JSON.parse(JSON.stringify(e)))
            }}
          />
          <MultiSelectWithSearch
            placeholder={ t('pages.activityList.globalSchedule.filters.labelSpeaker')}
            items={speakerOptions}
            onSelectionChange={(e) => setSelectedSpeakers(e)}
          />
        </WrapperFilters>
      </DinamicContainerFilters>

      {/* Tabela de Slots */}
      <StyledTableContainer
        ref={containerRef}
        component={Paper}
        sx={(theme) => ({ 
          maxHeight: "650px", 
          overflowY: 'auto', 
          overflowX: 'hidden', 
          border: "1px solid " + (theme.palette.mode === 'light' ? '#ddd' : '#111'),
         })}
      >
        <Table stickyHeader size="small">
          <TableHeadCustom spaces={spaces} labelHour={ t('pages.activityList.globalSchedule.time')}/>
          <TableBody>
            {slotsByHour.map((hourGroup, groupIndex) => {
              const groupRowSpan = hourGroup.length;
              const mainHour = hourGroup[0].split(':')[0];

              return hourGroup.map((slot, slotIndex) => {
                const rowData = tableData.find((r) => r.time === slot);
                if (!rowData) return null;

                const isCurrentHour =
                  slotsByHour.flat().indexOf(slot) === closestSlotIndex;

                  const currentHour = now.getHours();
                  const startHour = parseInt(mainHour, 10); // Hora inicial do grupo
                  const endHour = startHour + 1; // Hora final do grupo
            
                  // Verifica se a hora atual está entre startHour (inclusivo) e endHour (exclusivo)
                  const isInInterval = currentHour >= startHour && currentHour < endHour

                return (
                  <TableRow
                    key={slot}
                    sx={(theme) => ({
                      backgroundColor:
                        (groupIndex % 2 === 0
                          ? theme.palette.background.default
                          : theme.palette.mode === 'light'
                            ? theme.palette.background.paper
                            : '#555') + ' !important',
                    })}
                  >
                    {slotIndex === 0 && (
                      <StickyTableCell rowSpan={groupRowSpan} sx={{ fontWeight: 'bold', borderRight: isInInterval ?  "2px solid red": "none" }}>
                        {`${mainHour}:00`}
                      </StickyTableCell>
                    )}
                    {rowData.cells.map((cell, colIndex) => {
                      if (cell.skipCell) return null;
                      const { activity, rowSpan } = cell;

                      if (!activity) {
                        return (
                          <StyledTableCell
                            key={colIndex}
                            sx={{
                              borderTop: isCurrentHour ? '2px dashed red !important' : 'none',
                            }}
                          />
                        );
                      }

                      const clr = tracks.find((t) => t.id === activity.track)?.color ?? '#ccc';

                      return (
                        <StyledTableCell
                          key={colIndex}
                          rowSpan={rowSpan}
                        >
                          <ActivityCard
                            activity={activity}
                            rowSpan={rowSpan}
                            onClick={() => handleCardClick(activity)}
                            color={clr}
                            trackColor={clr}
                            isCurrentHour={isCurrentHour}
                          />
                        </StyledTableCell>
                      );
                    })}
                  </TableRow>
                );
              });
            })}
          </TableBody>
        </Table>
      </StyledTableContainer>
      
      <FilterTracks
        tracks={tracks}
        selectedTracks={selectedTracks}
        onToggleTrack={handleTrackToggle}
      />

      {/* Modal de detalhes da atividade */}
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box>
          <ActivityModal
            open={modalOpen}
            onClose={handleCloseModal}
            selectedActivity={selectedActivity}
          />
        </Box>
      </Modal>
    </MainContainer>
  );
}
