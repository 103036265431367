import React from 'react';
import { CardContent, Typography, Box, Chip } from '@mui/material';
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';
import CoPresentOutlinedIcon from '@mui/icons-material/CoPresentOutlined';

import { ActivityCard as StyledActivityCard } from '../styles/ScheduleGridStyles';

export default function ActivityCard({
  activity,
  rowSpan,
  onClick,
  color,
  trackColor,
}) {
  return (
    <StyledActivityCard
      //color={color}
      onClick={onClick}
      sx={{
        minHeight: `${rowSpan * 4}px`,
        display: 'flex',
        flexDirection: 'column',
        border: "1px solid " + color
      }}
    >
        <CardContent 
            sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                background: color+'a1'
            }}
        >     
            <Box>
                <Typography
                  variant="subtitle2"
                  sx={(theme) => ({
                      marginBottom: '4px',
                      fontWeight: 'bold', 
                      fontSize: '1rem',
                      letterSpacing: '1px',
                      borderBottom: '1px solid ' + theme.palette.dividers.card,
                      paddingBottom: '4px',
                      opacity: 0.9
                  })}
                >
                  <strong>{activity.title}</strong>
                </Typography>
                <Box sx={{display: "flex", flexDirection: "row", gap: "2px", opacity: 0.7, marginBottom: "8px"}} >
                  <QueryBuilderOutlinedIcon style={{fontSize: "16px", marginTop: "2px"}}/>
                  <Typography variant="caption">
                      {activity.startTime} - {activity.endTime}
                  </Typography>
                </Box>
                </Box>
                <Box sx={{marginTop: "20px"}}>
                  <Box sx={{display: "flex", flexDirection: "row", gap: "2px", opacity: 0.7, marginBottom: "8px"}} >
                    <CoPresentOutlinedIcon style={{fontSize: "16px", marginTop: "2px"}}/>
                    <Typography variant="caption" display="block">
                        {activity.speaker}
                    </Typography>
                  </Box>
                <Chip
                  label={activity.trackName}
                  sx={{
                      backgroundColor: color,
                      color: '#fff',
                      fontSize: '0.75rem',
                      justifyContent: "flex-start",
                  }}
                  className="track-chip"
                />
                </Box>
        </CardContent>
    </StyledActivityCard>
  );
}
