import React, { useEffect, useRef, useState } from 'react';
import {
  TextField,
  Checkbox,
  MenuItem,
  IconButton,
  Box,
  Typography,
  Chip,
  //Select,
  //FormControl,
  //InputLabel,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

export default function MultiSelectWithSearch({
  items = [],
  placeholder = 'Buscar...',
  icon = <SearchIcon />,
  onSelectionChange,
}) {
  const wrapperRef = useRef(null)
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
        if(wrapperRef.current && !wrapperRef.current.contains(event.target) && dropdownOpen) setDropdownOpen(false)
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
        document.removeEventListener('mousedown', handleClickOutside)
    }
}, [wrapperRef, dropdownOpen])


  const textFieldRef = useRef(null); // Referência para o TextField
  const [dropdownWidth, setDropdownWidth] = useState(0);

  // Atualiza o tamanho do dropdown quando o TextField muda de tamanho
  useEffect(() => {
    if (textFieldRef.current) {
      const { width } = textFieldRef.current.getBoundingClientRect();
      setDropdownWidth(width);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textFieldRef?.current]);


  // Filtro dos itens com base no termo de pesquisa
  const filteredItems = items.filter((text) => {

    const normalizeText = (text) =>
      text
        ?.normalize("NFD") // Decompõe os caracteres em partes (e?.g?., "é" vira "é")
        ?.replace(/[\u0300-\u036f]/g, "") // Remove os diacríticos
        ?.toLowerCase(); // Converte para minúsculas
  
      return normalizeText?.(text)?.toLowerCase().includes(normalizeText?.(searchTerm?.toLowerCase()))
  });

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleToggle = (item) => {
    const updatedSelection = selectedItems.includes(item)
      ? selectedItems.filter((i) => i !== item)
      : [...selectedItems, item];

      
    setSelectedItems(updatedSelection);
    //if (onSelectionChange) {
      //onSelectionChange(selectedItems);
    //}
  };

  useEffect(() => {
    if(onSelectionChange) onSelectionChange?.(selectedItems)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems])

  const [inputOnFocus, setInputOnFocus] = useState(false);
  const [mouseOver, setMouseOver] = useState(false);

  useEffect(() => {
    setDropdownOpen((mouseOver || inputOnFocus))
  }, [searchTerm, inputOnFocus, mouseOver])


  return (
    <Box sx={{ width: '100%', minWidth: "280px" }}>
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        mb: 2, 
        //width: '580px',
        maxWidth: "100%"
      }}>
        <TextField
          ref={textFieldRef}
          fullWidth
          placeholder={placeholder}
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
          onFocus={() => setInputOnFocus(true)}
          onBlur={() => setInputOnFocus(false)}
          InputProps={{
            startAdornment: (
              <IconButton edge="start">
                {icon}
              </IconButton>
            ),
          }}
        />
      </Box>

      {dropdownOpen && (
        <Box 
        ref={wrapperRef}
          onMouseOver={() => setMouseOver(true)}
          onMouseOut ={() => setMouseOver(false)}
          sx={(theme) => ({ 
            maxHeight: 200, 
            overflowY: 'auto', 
            border: '1px solid #ccc', 
            borderRadius: "12px",
            padding: "8px",
            marginTop: -"14px",
            position: "absolute",
            zIndex: 999,
            backgroundColor: theme.palette.background.paper,
            width: `${dropdownWidth}px`,
            maxWidth: "100%",
            minWidth: "280px",
            // Estilização do scroll
            '&::-webkit-scrollbar': {
              width: '8px',
              height: '8px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: theme.palette.background.default,
              borderRadius: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#aaa',
              borderRadius: '8px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#aaa',
            },
          })}
        >
          {filteredItems.length > 0 ? (
            filteredItems.map((item) => (
              <MenuItem key={item} onClick={() => handleToggle(item)}>
                <Checkbox checked={selectedItems.includes(item)} />
                {item}
              </MenuItem>
            ))
          ) : (
            <Typography variant="body2" color="textSecondary">
              Nenhum resultado obtido.
            </Typography>
          )}
        </Box>
      )}
      <div style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        marginBottom: "20px",
        alignItems: "flex-start",
        gap: "20px",
        flexWrap: "wrap"
      }}>
        {selectedItems.map((t, index) => (
          <Chip
            key={t}
            label={
              <div style={{
                display: "flex", 
                justifyContent: "flex-start", 
                alignItems: "center", 
                gap: "4px", 
                maxWidth: '380px',
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis"
              }}>
                  <CancelOutlinedIcon style={{fontSize: "18px"}}/> 
                  <span style={{
                    maxWidth: '360px',
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis"
                  }}>{t}</span>
              </div>
            }
            sx={(theme) => ({
              backgroundColor: theme.palette.mode === "dark" ? "#555" :  "#ddd",
              //marginLeft: index === 0 ? "80px" : "0px"
            })}
            onClick={() => handleToggle(t)}
            clickable
          />
        ))}
      </div>
    </Box>
  );
}
