import React from 'react';
import { Box } from '@mui/material';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';

export default function ArrowButtonsNavigator({
    handlePrevColumn,
    visibleColumnIndex,
    handleNextColumn,
    spaces
}) {
  return (
    <Box 
    sx={{
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center",
        gap: "20px"
    }}
    >
        <Box
            variant="contained"
            onClick={handlePrevColumn}
            disabled={visibleColumnIndex === 0}
            sx={{
            display: "flex", 
            justifyContent: "center", 
            alignItems: "center",
            cursor: "pointer"
            }}
        >
            <ArrowBackIosNewOutlinedIcon/>
        </Box>
        <Box
            variant="contained"
            onClick={handleNextColumn}
            disabled={visibleColumnIndex === spaces.length - 1}
            sx={{
            display: "flex", 
            justifyContent: "center", 
            alignItems: "center",
            cursor: "pointer"
            }}
        >
            <ArrowForwardIosOutlinedIcon/>
        </Box>
    </Box>
  );
}
