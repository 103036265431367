import React from 'react';
import { Box } from '@mui/material';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';

export default function ButtonVisibleFilters({
    visibleFilters,
    setVisibleFilters,
    labelShowFilters = "Exibir filtros",
    labelHiddenFilters = "Ocultar filtros",
    ...rest
}) {
  return (
    <Box 
        data-visiblefilters={visibleFilters}
        {...rest} // Passa apenas props válidas para Box
        sx={(theme) => ({
            display: 'flex', 
            flexDirection: "row", 
            justifyContent: "space-between", 
            alignItems: "center", gap: "4px",background: theme.palette.background.paper, 
            //border: "1px solid " + (theme.palette.mode === 'light' ? '#ddd' : '#111'),
            borderRadius: "8px",
            padding: "4px",
            paddingLeft: "16px",
            paddingRight: "16px",
            cursor: "pointer"
        })}
        onClick={() => setVisibleFilters(prev => !prev)}
    >
        {visibleFilters ? labelHiddenFilters : labelShowFilters}
        <TuneOutlinedIcon/>
    </Box>
  );
}
