import { useState, useMemo, useEffect } from 'react';

export default function useActivitiesFilter(activities, dayActs) {
  const [selectedTracks, setSelectedTracks] = useState([]);
  const [selectedSpeakers, setSelectedSpeakers] = useState([]);
  const [selectedActivities, setSelectedActivities] = useState([]);

  // Lista de palestrantes a partir das atividades 
  const speakerOptions = useMemo(() => {
    const speakerSet = new Set();
    Object.values(activities).forEach((acts) => {
      acts.forEach((act) => {
        if (act.speaker) {
          speakerSet.add(act.speaker);
        }
      });
    });
    return Array.from(speakerSet);
  }, [activities]);

  // Lista de palestrantes a partir das atividades 
  const activitiesOptions = useMemo(() => {
    const titleActivitiesSet = new Set();
    Object.values(activities).forEach((acts) => {
      acts.forEach((act) => {
        if (act.title) {
          titleActivitiesSet.add(act.title);
        }
      });
    });
    return Array.from(titleActivitiesSet);
  }, [activities]);

  // Função de filtragem final
  const filterActivities = (list, selectedTracks, selectedSpeakers, selectedActivities) => {
    return list.filter((act) => {
      // Se nenhum filtro estiver ativo, retorna todos os itens
      if (
        (!selectedTracks || selectedTracks.length === 0) &&
        (!selectedSpeakers || selectedSpeakers.length === 0) &&
        (!selectedActivities || selectedActivities.length === 0)
      ) {
        return true;
      }
  
      const hasResult = [];
  
      // Verifica se a track está incluída no filtro
      if (selectedTracks && selectedTracks.length > 0) {
        hasResult.push(selectedTracks.includes(act.track));
      }
  
      // Verifica se o speaker está incluído no filtro
      if (selectedSpeakers && selectedSpeakers.length > 0) {
        hasResult.push(selectedSpeakers.includes(act.speaker));
      }
  
      // Verifica se a atividade está incluída no filtro
      if (selectedActivities && selectedActivities.length > 0) {
        hasResult.push(selectedActivities.includes(act.title));
      }
  
      // Retorna true se pelo menos uma das condições for verdadeira
      return hasResult.some((result) => result === true);
    });
  };

  const [filtered, setFiltered] = useState(filterActivities(dayActs, selectedTracks, selectedSpeakers, selectedActivities))

  useEffect(() => {
    setFiltered(filterActivities(dayActs, selectedTracks, selectedSpeakers, selectedActivities))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dayActs, selectedTracks, selectedSpeakers, selectedActivities ])

  const handleTrackToggle = (trackId) => {
    setSelectedTracks((prev) =>
      prev.includes(trackId) ? prev.filter((t) => t !== trackId) : [...prev, trackId]
    );
  };


  return {
    //searchTerm,
    selectedTracks,
    selectedSpeakers,
    speakerOptions,
    activitiesOptions,
    selectedActivities,
    filterActivities,
    setSelectedSpeakers,
    setSelectedActivities,
    handleTrackToggle,
    filtered
  };
}
