// src/styles/ScheduleGridStyles.jsx
import { styled } from '@mui/material/styles';
import { 
  TableCell, 
  TableContainer, 
  Card, 
  Box 
} from '@mui/material';

export const MainContainer = styled(Box)(({ theme }) => ({
  width: '100%', 
  padding: "16px", 
  background: 
  theme.palette.background.paper, 
  borderRadius: "16px",
  overflow: "hidden"
}));

export const WrapperHeaderOptionsTable = styled(Box)(({ theme }) => ({
  display: 'flex', 
  flexDirection: "row", 
  justifyContent: "space-between", 
  alignItems: "center", 
  flexWrap: "wrap", 
  gap: "8px",
  [`@media (max-width: 500px)`]: {
    flexDirection: "column-reverse",
    alignItems: "flex-start"
  },
}));


export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  maxHeight: 400,
  overflowY: 'auto',
  borderRadius: "0px",
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: theme.palette.background.default,
    borderRadius: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#aaa',
    borderRadius: '8px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#aaa',
  },
  [`@media (max-width: 500px)`]: {
    overflowX: "auto"
  },
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '8px',
  maxWidth: '220px',
  width: '220px',
  minWidth: '220px',
  height: '5px',
  borderBottom: '0px !important',
  borderLeft: `1px solid ${
    theme.palette.mode === 'light' ? '#ddd' : '#111'
  }`,
  [theme.breakpoints.down('sm')]: {
    padding: '4px',
  },
}));

export const StickyTableCell = styled(TableCell)(({ theme }) => ({
  position: 'sticky',
  left: 0,
  background: theme.palette.background.paper,
  zIndex: theme.zIndex.appBar,
  [`@media (max-width: 500px)`]: {
    maxWidth: '60px',
    padding: "0px",
    textAlign: "center"
  },

}));

export const DinamicContainerFilters = ({ visibleFilters, ...props }) => (
  <Box {...props} sx={{
    maxHeight: visibleFilters ? "500px" : "0px",
    overflow: "hidden",
    transition: "max-height 0.3s ease",
  }} />
);


export const WrapperFilters =  styled(Box)(({ theme }) => ({
  display: 'flex', 
  flexWrap: "wrap",
  flexDirection: 'row', 
  gap: '20px', background: theme.palette.background.paper, 
  border: "1px solid " + (theme.palette.mode === 'light' ? '#ddd' : '#111'),
  borderBottomWidth: "0px",
  padding: "16px",
  paddingBottom: "0px"
}));


export const ActivityCard = styled(Card)(({ color }) => ({
  //backgroundColor: color || '#fff', // fallback
  height: '100%',
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.9,
  },
}));

export const ModalContent = styled(Box)(({ theme }) => ({
  maxHeight: "98vh",
  overflow: "auto",
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: 600,
  backgroundColor: theme.palette.background.default,
  boxShadow: '0px 4px 20px rgba(0,0,0,0.2)',
  padding: 32,
  borderRadius: 8,
}));
