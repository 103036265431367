// src/utils/index.js

// Gera array de horas e minutos (slots de 5 em 5 minutos, etc.)
export function generateSlotsByHour(startHour, endHour, step) {
    const result = [];
    for (let h = startHour; h < endHour; h++) {
      const hourGroup = [];
      for (let m = 0; m < 60; m += step) {
        const hh = String(h).padStart(2, '0');
        const mm = String(m).padStart(2, '0');
        hourGroup.push(`${hh}:${mm}`);
      }
      result.push(hourGroup);
    }
    return result;
  }
  
  // Converte data "DD-MM-YYYY" para "D de mês_por_extenso de YYYY"
  export function converterDataPtBr(dateStr) {
    const MESES_PT = [
      'janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho',
      'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro',
    ];
    
    const [diaStr, mesStr, anoStr] = dateStr.split('-'); 
    const dia = parseInt(diaStr, 10);
    const mes = parseInt(mesStr, 10);
    const ano = parseInt(anoStr, 10);
  
    return `${dia} de ${MESES_PT[mes - 1]} de ${ano}`;
  }
  
  export function converterDataParaIdioma(dateStr, idioma) {
    const MESES = {
      'pt-BR': [
        'janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho',
        'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro',
      ],
      'en-US': [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December',
      ],
      'es-ES': [
        'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio',
        'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre',
      ],
    };

    let traducaoMeses = (idioma && MESES?.[idioma]) ? MESES[idioma] : MESES['pt-BR']
  
    const [diaStr, mesStr, anoStr] = dateStr.split('-'); 
    const dia = parseInt(diaStr, 10);
    const mes = parseInt(mesStr, 10);
    const ano = parseInt(anoStr, 10);
  
    if (idioma === 'en-US') {
      return `${traducaoMeses?.[mes - 1]} ${dia}, ${ano}`;
    } else {
      return `${dia} de ${traducaoMeses?.[mes - 1]} de ${ano}`;
    }
  }

  // Monta a estrutura de dados de linhas e colunas para renderizar na tabela
  export function buildTableData({ dayActivities, slotsByHour, spaces }) {
    const allSlots = [];
    slotsByHour.forEach((hourGroup) => {
      hourGroup.forEach((slot) => allSlots.push(slot));
    });
  
    const rows = allSlots.map((time) => ({
      time,
      cells: spaces.map((space) => ({
        space: space.title,
        activity: null,
        rowSpan: 1,
        skipCell: false,
      })),
    }));
  
    dayActivities.forEach((act) => {
      const { startTime, endTime, space } = act;
      const startIndex = allSlots.indexOf(startTime);
      const endIndex = allSlots.indexOf(endTime);
      if (startIndex === -1 || endIndex === -1) return;
      const span = endIndex - startIndex;
      const row = rows[startIndex];
      if (!row) return;
      const cellIndex = spaces.findIndex((s) => s.title === space);
      if (cellIndex === -1) return;
      const cell = row.cells[cellIndex];
      cell.activity = act;
      cell.rowSpan = span > 0 ? span : 1;
  
      for (let i = startIndex + 1; i < startIndex + span; i++) {
        if (rows[i]) {
          rows[i].cells[cellIndex].skipCell = true;
        }
      }
    });
  
    return rows;
  }
  